import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import ContactConfirm from "../components/ContactConfirm/ContactConfirm"
import { useDispatch } from "react-redux"
import { sendContactAsync } from "../redux/contactFormSlice"
import { navigate } from "gatsby-link"

const Confirmation = ({ location }) => {
  const { city, id_tra, id_chi, sendPayload } = location.state
  const dispatch = useDispatch()

  const sendToChirurgoId = id => {
    dispatch(sendContactAsync({ ...sendPayload, id_chirurgo: id }, () => {}))
  }

  return (
    <Layout>
      <ContactConfirm
        comune={city}
        id_tra={id_tra}
        id_chi={id_chi}
        onClose={() => navigate("/")}
        sendToChirurgoId={sendToChirurgoId}
      />
    </Layout>
  )
}

export default Confirmation
